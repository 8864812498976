import { graphql, StaticQuery } from "gatsby"

import BackgroundImage from "gatsby-background-image"
import React from "react"
// ****************** Designed by Theewra. Developed by Explorelogy. ******************
import Layout from "../components/layout"
import Seo from "../components/seo"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import { useState } from "react"

export default class Careers extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query career {
            blog: file(relativePath: { eq: "blog/blog.jpg" }) {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }

            project: file(relativePath: { eq: "careers/Tech pac copy.png" }) {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            personal_assistant: file(
              relativePath: { eq: "careers/personal_assistant.png" }
            ) {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            Sales_Executive: file(
              relativePath: { eq: "careers/Sales_Executive.jpg" }
            ) {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            sales: file(relativePath: { eq: "careers/sales.jpg" }) {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        `}
        render={data => {
          const images = {
            blog: convertToBgImage(getImage(data.blog)),
            project: getImage(data.project),
            sales: getImage(data.sales),
            personal_assistant: getImage(data.personal_assistant),
            Sales_Executive: getImage(data.Sales_Executive),
          }

          const careers = [
            {
              title: "Project Coordinator",
              caption:
                "Join our team and shape your future! We're hiring a Project Coordinator to oversee our projects and work closely with multiple departments. If you're a proactive problem solver with excellent communication skills, apply now! ",
              image: images.project,
            },
            {
              title: "Personal Assistant",
              caption:
                "Join Tech Pacific Lanka's team as a skilled Personal Assistant to the CEO! We are looking for an exceptional organizer who can thrive in a fast-paced environment. Apply now at careers@techpac.lk and take your career to new heights.",
              image: images.personal_assistant,
            },
            {
              title: "Sales Executive",
              caption:
                "Join Tech Pacific Lanka's winning team as a Sales Executive for our Retail Division. Leverage your skills, experience, and passion to drive sales growth and develop strong client relationships. Take the next step in your career by applying today at careers@techpac.lk.  ",
              image: images.Sales_Executive,
            },
          ]

          const [active, setActive] = useState(0)

          return (
            <>
              <Layout>
                <Seo title="Careers | Tech Pacific Lanka" />
                <section>
                  <BackgroundImage {...images.blog}>
                    <div className="blog-cover">
                      <div className="">
                        <div className="container">
                          <div className="blog-title">Careers</div>
                        </div>
                      </div>
                    </div>
                  </BackgroundImage>
                </section>

                <div className="container">
                  <div className="main-section-careers row">
                    <div className="col-md-3 main-title-careers">
                      {careers.map((item, index) => {
                        return (
                          <div
                            className={
                              active == index
                                ? "active-careers-item title-section-careers"
                                : " title-section-careers"
                            }
                            key={index}
                            onClick={() => setActive(index)}
                          >
                            {item.title}
                          </div>
                        )
                      })}
                    </div>
                    <div className="col-md-9">
                      <div className="blog-title">{careers[active].title} </div>

                      <p> {careers[active].caption} </p>

                      <div className="careers-image relative">
                        <GatsbyImage
                          placeholder="blurred"
                          image={careers[active].image}
                          alt="project"
                        />
                        <a href="mailto:careers@techpac.lk">
                          <div className="careers-image-anchor"></div>
                        </a>
                      </div>
                      <div className="row align-items-center main-apply-section">
                        <div className="col-md-7">
                          <div className="title-apply">Apply Now</div>
                          <div className="sub-apply-title">
                            Please e-mail us a copy of your CV
                          </div>
                        </div>
                        <div className="col-md-3">
                          <a href="mailto:careers@techpac.lk">
                            <div className="apply-button">Apply</div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Layout>
            </>
          )
        }}
      ></StaticQuery>
    )
  }
}
